import { useRouter } from 'next/router'
import Link from 'next/link'
import languageDetector from '../../../lib/languageDetector'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'

const LanguageSwitchLink = ({
  setOpen,
  currentLanguage,
  locale,
  item,
  ...rest
}) => {
  const { i18n } = useTranslation('common')
  const router = useRouter()

  const localeRegex = /^\/(en|vi|zh)(\/|$)/

  const renderHref = () => {
    let href = rest.href || router.asPath
    if (localeRegex.test(href)) {
      href = href.replace(localeRegex, locale === 'en' ? '/' : `/${locale}/`)
    } else {
      href = locale === 'en' ? href : `/${locale}${href}`
    }
    i18n.changeLanguage(locale)
    languageDetector.cache(locale)
    router.push(href)
  }

  return (
    <div>
      <div
        className={`dd-lang-item ${
          currentLanguage === item.id ? 'active' : ''
        }`}
        onClick={() => {
          setOpen(false)
          renderHref()
        }}
      >
        <Image
          width={30}
          height={24}
          src={`/images/common/${item.id}.png`}
          alt={item.id}
        />
        {item.name}
      </div>
    </div>
  )
}

export default LanguageSwitchLink
