import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import axiosInstance from '../../utils/auth/axiosInstance'
import { API_BASE_URL } from '../../constants'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

function PopupCheckActivationESIMStatus({ isOpen, setIsOpen }) {
  const [form] = Form.useForm()
  const [isSearching, setIsSearching] = useState(false)
  const router = useRouter()
  const { t, i18n } = useTranslation('landingPage')

  useEffect(() => {
    if(!isOpen) {
      form.resetFields()
    }
  }, [isOpen])


  const handleOk = () => {
    setIsOpen(false)
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  const onFinish = async (values) => {
    setIsSearching(true)
    try {
      const res = await axiosInstance.get(
        `${API_BASE_URL}/api/v1/landing-products/esim-register-infos/${values?.id_number}`
      )

      if (res?.data?.data?.items?.length) {
        form.resetFields()
        setIsOpen(false)
        router.push(`/${i18n?.language || 'en'}/sim-activation-management/${values?.id_number}`)
      } else {
        form.setFields([
          {
            name: 'id_number',
            errors: [t('not_found_id_pasport')],
          },
        ])
      }
    } catch (error) {
      message.error(t('error_processing'))
    } finally {
      setIsSearching(false)
    }
  }

  return (
    <Modal open={isOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
      <div className="modal-information-sim-activate">
        <div className="information-sim-activate-title">
          {t('check_esim_activation_status')}
        </div>
        <Form onFinish={onFinish} layout="vertical" form={form} name="form">
          <Form.Item
            name="id_number"
            rules={[{ required: true, message: t('please_input_id_pasport') }]}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder={t('input_id_pasport')}
            />
          </Form.Item>
        </Form>

        <Button
          loading={isSearching}
          disabled={isSearching}
          className="w-100 mt-1 mb-1 login-button"
          onClick={() => form.submit()}
        >
          {t('confirm')}
        </Button>
      </div>
    </Modal>
  )
}

export default PopupCheckActivationESIMStatus
